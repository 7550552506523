<template>
  <LayoutTelas id="tela__veiculos" :titulo="this.$route.meta.titulo">
    <div class="about">
      <p>Esta é a página do lançamento contábil</p>
    </div>
    <LancamentosForm/>
    
  </LayoutTelas>
  
</template>

<script>
import LancamentosForm from "./forms/LancamentosForm";

export default {
  data() {
    return {
      dataInventario: '',
    }
  },
  components: {
    LancamentosForm
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

#tela__veiculos {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: $medium-size) {
    min-width: $small-size;
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}
</style>