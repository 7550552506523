<template>
  <div>
    <loading
      :active.sync="dados.isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#f68b29"/>
    <b-form
      class="filtro"
      @submit.prevent="processarLancamentos(dados.tipoNota)">
      <InputBasico
        ref="refMesLancamentos"
        label="Data inicial"
        name="mesLancamentos"
        v-model="dados.dataInicial"
        :disabled="dados.disabledCampos"
        type="date"
        min="1"
        max="12"
        class="inputMesLancamentos"
        v-if="dados.visivel"/>
      <InputBasico
        ref="refMesLancamentos"
        label="Data final"
        name="mesLancamentos"
        v-model="dados.dataFinal"
        :disabled="dados.disabledCampos"
        type="date"
        min="1"
        max="12"
        class="inputFilialEmpresa"
        v-if="dados.visivel"/>

      <div class="loteCard">
        <b-form-checkbox
          v-model="dados.checked"
          name="check-button"
          switch
          :disabled="dados.disabledCampos">Contábilidade por lote</b-form-checkbox>
        <b-collapse id="collapse-1" v-model="dados.checked" class="clpDoc">
          <InputBasico
            ref="refAnoExercicio"
            label="Ano Exercício"
            name="anoExercicio"
            v-model="dados.anoExercicio"
            :disabled="dados.disabledCampos"
            type="text"
            class="inputAnoExercicio"/>
          <InputBasico
            ref="refLote"
            label="Código do lote"
            name="loteLancamentos"
            v-model="dados.loteLancamentos"
            :disabled="dados.disabledCampos"
            type="text"
            class="inputLoteLancamentos"/>
        </b-collapse>
      </div>

      <b-button
        type="submit"
        variant="primary"
        class="btnProcessar"
        :disabled="dados.disabledCampos">Processar</b-button>

      <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>
      <div class="loader" v-if="dados.show_loader_erp">
        <b-spinner
          v-if="!dados.checked_erp"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."></b-spinner>
        <v-icon
          v-if="dados.checked_erp"
          scale="2"
          name="check"
          class="loaderCheck"/>
        <label class="concluido">Carregando dados ERP...</label>
      </div>

      <div class="loader" v-if="dados.show_loader_produto">
        <b-spinner
          v-if="!dados.checked_produto"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."></b-spinner>
        <v-icon
          v-if="dados.checked_produto"
          scale="2"
          name="check"
          class="loaderCheck"/>
        <label class="concluido">Carregando produtos...</label>
      </div>

      <div class="loader" v-if="dados.show_loader_cliente">
        <b-spinner
          v-if="!dados.checked_cliente"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."></b-spinner>
        <v-icon
          v-if="dados.checked_cliente"
          scale="2"
          name="check"
          class="loaderCheck"/>
        <label class="concluido">Carregando clientes...</label>
      </div>

      <div class="loader" v-if="dados.show_loader_fornecedor">
        <b-spinner
          v-if="!dados.checked_fornecedor"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."></b-spinner>
        <v-icon
          v-if="dados.checked_fornecedor"
          scale="2"
          name="check"
          class="loaderCheck"/>
        <label class="concluido">Carregando fornecedores...</label>
      </div>

      <div class="loader" v-if="dados.show_loader_lancamentos">
        <b-spinner
          v-if="!dados.checked_lancamentos"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."></b-spinner>
        <v-icon
          v-if="dados.checked_lancamentos"
          scale="2"
          name="check"
          class="loaderCheck"/>
        <label class="concluido">Processando o lançamentos...</label><br>
        <label class="concluido" v-if="!dados.checked_lancamentos && dados.show_lote_orig != ''">
        Em andamento:<br>
         - Progresso: {{perc_progresso}}<br>
         - Lote Origem: {{dados.show_lote_orig}}<br>
         - Lote Destino/Lançamento: {{dados.show_lote_dest}}-{{dados.show_lote_dest_idx}}
        </label>        
      </div>      
    </b-form>

    <div>
      <b-table
        striped
        hover
        bordered
        head-variant="dark"
        class="tableLancamento"
        v-if="dados.itemProcessamento && dados.itemProcessamento.retornoLoteContabil.length > 0"
        :items="dados.itemProcessamento.retornoLoteContabil"
        :fields="dados.fieldsLancamentos"></b-table>
    </div>
  </div>
</template>

<script>
import {
  processaLancamentos,
  getListaProcessamentos,
} from "../../../common/services/lancamentosService";

import * as dataHelper from '@/common/helpers/DataHelper'
import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import { getUserObj } from "@/common/localStorage/localStorage";

export default {
  data() {
    return {
      dados: {
        dataInicial: "",
        dataFinal: "",
        produtoLancamentos: "",
        anoExercicio:"",
        filialEmpresa: "",
        checked: false,
        checked_erp: false,
        checked_fornecedor: false,
        checked_cliente: false,
        checked_produto: false,
        checked_lancamentos: false,
        show_loader_erp: false,
        show_loader_fornecedor: false,
        show_loader_cliente: false,
        show_loader_produto: false,
        show_loader_lancamentos: false,
        show_lote_orig: "",
        show_lote_dest: "",
        show_lote_dest_idx: "",
        total_lote: 0,
        total_lotes_integ: 0,
        isConcluido: false,
        disabledCampos: false,
        itemProcessamento: null,
        key: false,
        isLoading: true,
        visivel: getUserObj().erp.tipo && getUserObj().erp.tipo != 'INFORLN',
        fieldsLancamentos: [
          { key: "codEmp", label: "Código empresa", sortable: true },
          { key: "codFil", label: "Código filial", sortable: true },
          { key: "ctaCre", label: "Conta crédito", sortable: true },
          { key: "ctaDeb", label: "Conta débito", sortable: true },
          { key: "numLot", label: "Número lote", sortable: true },
          { key: "vlrLct", label: "Valor", sortable: true },
          { key: "resultado", label: "Msg retorno", sortable: true },
        ],
      },
    };
  },
  components: {
    InputBasico,
    InputSelect,
  },
  created: async function() {
    await this.getListaProcessamentos()

    if (!this.dados.visivel) {
      this.dados.dataInicial = dataHelper.atualDateYYYYMMDD('-');
      this.dados.dataFinal = dataHelper.atualDateYYYYMMDD('-');
    }
  },
  sockets: {
    ALTERAR_FORM_LANCAMENTOS: async function (data) {
      this.dados.disabledCampos = true;
      Object.keys(data).forEach((key) => {
        this.dados[key] = data[key];
      });
    },
    HABILITAR_FORM_LANCAMENTOS: async function (data) {
      this.dados.disabledCampos = false;
    },
    RETORNO_PROCESSAMENTO_FORM_LANCAMENTOS: async function (data) {
      // console.log(data)
      this.dados.itemProcessamento = data
      this.$validator.reset()
      this.errors.clear()
    }
  },
  computed: {
    perc_progresso() {
      let resultado = "";
      if (this.dados.total_lote > 0) {
        resultado = (parseInt((this.dados.total_lotes_integ * 100) / this.dados.total_lote)).toString() + "%"   
      } else {
        resultado = "0%"
      }
      return resultado;
    }
  },
  methods: {
    getFiliais() {
      let filiais = [
        {
          codFilial: "Todas",
          codFil: "Todas",
          codDep: "TODAS",
          codFiltro: "",
          status: true,
        },
      ];
      filiais.push(...getUserObj().filiais);
      console.log();
      return filiais.map((item) => {
        return { text: `${item.codFil}`, value: `${item.codFiltro}` };
      });
    },
    montaDadosEnvio() {
      let retorno = {
        date_low: this.dados.dataInicial,
        date_high: this.dados.dataFinal,
      };
      if (this.dados.checked) {
        retorno = {
          date_low: "",
          date_high: "",
        };
        retorno.listaContabil = [
          {
            empresa: getUserObj().empresa.idSAP,
            exercicio: this.dados.anoExercicio,
            lote: this.dados.loteLancamentos,
          },
        ];
      }
      return retorno;
    },
    async processarLancamentos() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let ret = await processaLancamentos(this.montaDadosEnvio());
          this.dados.isLoading = false;
          this.key = !this.key;
        }
      });
    },
    async getListaProcessamentos() {
      let r
      try {
        r = (await getListaProcessamentos(1)).data
      } catch (error) {
        console.error(error)
      }finally{
        if(r)this.dados.itemProcessamento = r[0]
        
        this.dados.isLoading = false
        this.key = !this.key
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

.filtro {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}
.clpDoc {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}

.input {
  &MesLancamentos {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }

  &AnoLancamentos {
    @media (min-width: $medium-size) {
      grid-column: 3 / 6;
    }
  }
  &AnoExercicio {
    @media (min-width: $medium-size) {
      grid-column: 1 / 3;
    }
  }
  &LoteLancamentos {
    @media (min-width: $medium-size) {
      grid-column: 3 / 6;
    }
  }
  &FilialEmpresa {
    @media (min-width: $medium-size) {
      grid-column: 4 / 8;
    }
  }
}

.btn {
  &Processar {
    @media (min-width: $medium-size) {
      grid-column: 1 / 3;
      margin-top: 1.9rem;
      margin-bottom: 1.9rem;
    }
  }
}
.concluido {
  @media (min-width: $medium-size) {
    grid-column: 3 / -1;
    align-self: center;
    padding-left: 0.5rem;
  }
}

.loader {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
    align-self: center;
  }
  &Spinner {
    align-self: center;
  }
  &Check {
    color: #00cf2d;
  }
}
.loteCard {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
  }
}

.tableLancamento {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
  }
}
</style>